
  import { Component, Emit, Prop, Ref, Watch } from 'vue-property-decorator'
  import { Generation, RotationIndex, VersionYear } from '@/entities/vehicle'
  import dayjs from 'dayjs'
  import { FilesProcess } from '@/components/forms/view/FilesProcess'
  import GCostField from '@/components/core/input/GCostField.vue'
  import { RULES } from '@/components/forms'
  import { Person } from '@/entities/persons'
  import { isValidNumber } from '@/utils/general'
  import GFiles from '@/components/core/files/GFiles.vue'
  import VersionCellManager from '@/components/dataTables/cell/VersionManager/VersionCellManager.vue'
  import CellManager from '@/components/dataTables/cell/VersionManager/CellManager.vue'
  import { plainToInstance } from 'class-transformer'
  import { mapActions } from 'vuex'
  import { Debounce } from '@/utils/decorators'

@Component({
  components: { CellManager, VersionCellManager, GFiles, GCostField },
  methods: {
    ...mapActions('resources/datatables', ['updateMileagePrice']),
  },
})
  export default class SiiRow extends FilesProcess {
  @Prop({ type: Object, default: null }) readonly person!: Person;
  @Prop({ type: VersionYear, default: null }) data!: VersionYear;
  @Prop() isSelected!: boolean;
  @Prop({ type: Boolean, default: false }) readonly displaySelector!: boolean;
  @Prop({ type: Array, default: () => [] }) readonly rotationIndex!: RotationIndex[];
  @Prop({ type: Number, default: null }) readonly idProcess!: number;
  @Prop({ type: Number, default: null }) readonly idProcessMileage!: number;
  @Prop({ type: String, default: null }) readonly aliasBrand!: string;
  @Prop({ type: String, default: null }) readonly aliasModel!: string;
  @Prop({ type: String, default: null }) readonly linkModel!: string;
  @Prop({ type: Object, default: () => null }) photoMileageProperties!: Record<string, any>;
  @Prop({ type: Array, default: () => [] }) readonly backupPhoto!: Array<Record<string, any>>;
  @Prop({ type: String, default: null }) readonly sku!: string;
  @Ref('form') readonly form: any;

  updateMileagePrice!: (payload: any) => Promise<any>
  send = false
  generation = null
  loading = false
  photo = null
  pdf = null
  rule = RULES.isRequired
  originalLinkChileautos = null
  linkRule = RULES.isUrl
  fuel = null
  transmission = null
  traction = null
  storeLinkChileautos = null
  idMileage = null
  idMileageChileautos = null

  open = false

  formData = {
    link: '',
    amount: '',
    amountFinanced: '',
    backupPhoto: [],
    rotationIndex: null,
    linkChileautos: '',
  };

  isLinkValid (link) {
    return this.linkRule.every(rule => rule(link) === true)
  }

  get linkRequired () {
    const { formData: { amount, amountFinanced, link, backupPhoto }, is0kmRequired } = this

    const isLinkValid = link?.length && this.isLinkValid(link)

    return Boolean(backupPhoto?.length) || Boolean(isValidNumber(amount) || isValidNumber(amountFinanced) || isLinkValid) || is0kmRequired
  }

  async sendData () {
    if (!this.form.validate()) return
    this.loading = true
    const { data, isLinkValid, linkModel, generation, formData, idProcessMileage, photoMileageProperties } = this
    const mileagePrices = data.mileagePrices?.filter((item: any) => item.mileageFrom === 0 && item.mileageTo === 0 && item.dateTo === null && !item?.person)
    const mileagePrice = mileagePrices.find((item: any) => (generation?.id && item.idGeneration === generation?.id))

    const mileageChileautosFilter = data.mileagePrices.filter((item: any) => item.mileageFrom === 0 && item.mileageTo === 100 && item.dateTo === null && item?.person)
    const mileageChileautos = mileageChileautosFilter?.find((item: any) => generation.id === item.idGeneration)

    if (isValidNumber(formData.amount) && isValidNumber(formData.amountFinanced)) {
      const fields = {
        id: mileagePrice?.id || this.idMileage,
        link: formData?.link || linkModel,
        amount: parseInt(formData.amount) || 0,
        amountFinanced: parseInt(formData.amountFinanced),
        mileageFrom: 0,
        mileageTo: 0,
        versionYearId: data.id,
        generationId: generation?.id,
      }

      const mileage = await this.updateMileagePrice(fields)
      this.idMileage = mileage.id

      if (formData.backupPhoto.length) {
        await this.handleFileType(formData.backupPhoto, photoMileageProperties, idProcessMileage, mileage.id)
      }
    }

    if (isLinkValid(this.formData.linkChileautos)) {
      await this.sendLinkChileAutos(mileageChileautos)
    }

    if (generation.rotationIndex !== this.formData.rotationIndex) {
      await this.sendRotationIndex(generation)
    }

    this.loading = false
    this.send = true
  }

  async sendLinkChileAutos (mileageChileautos) {
    const { data, formData, generation, person } = this
    const fieldsChileautos = {
      id: mileageChileautos?.id || this.idMileageChileautos,
      link: formData.linkChileautos,
      mileageFrom: 0,
      amount: 0,
      mileageTo: 100,
      versionYearId: data.id,
      generationId: generation?.id,
      personId: person.id,
    }

    const mileage = await this.updateMileagePrice(fieldsChileautos)

    this.idMileageChileautos = mileage.id
  }

  async sendRotationIndex (data) {
    const gen = await this.fetchData({
      query: { name: 'find', model: 'Generation' },
      filter: { id: { _eq: data.id } },
    })

    if (gen?.[0]?.rotationIndex?.id === this.formData.rotationIndex) return
    await this.pushData({
      model: 'Generation',
      fields: {
        id: data.id,
        id_rotation_index: this.formData.rotationIndex,
      },
    })
  }

  async emitVersionYear () {
    const { data, generation } = this

    data.generations = [plainToInstance(Generation, { id: generation.id })]
    this.$emit('open-detail', data)
  }

  async getGenerations (version): Promise<Generation[]> {
    return this.fetchData({
      query: { name: 'find', model: 'Generation' },
      filter: { version_year: { id_version: { _eq: version.version.id }, id_year: { _eq: version.year.id } } },
    })
  }

  @Emit('toggle-select')
  toggleSelection () {
    return this.data && this.data.id ? this.data.id : null
  }

  @Watch('data', { immediate: true })
  async onDataChange (val: any) {
    const generation = val.attrs.find((item: any) => item.sku === this.sku)
    this.generation = generation
    this.fuel = generation?.fuel?.value
    this.transmission = generation?.transmission?.value
    this.traction = generation?.traction?.value
    this.formData.rotationIndex = generation?.rotationIndex?.id
    const mileagePrices = val.mileagePrices?.filter((item: any) => item.mileageFrom === 0 && item.mileageTo === 0 && item.dateTo === null)
    const mileagePrice = mileagePrices.find((item: any) => (generation?.id && item.idGeneration === generation?.id))

    if (mileagePrice) {
      this.formData.link = mileagePrice.link
      this.formData.amount = mileagePrice.amount
      this.formData.amountFinanced = mileagePrice.amountFinanced
      this.formData.backupPhoto = await this.fetchData({
        query: { name: 'find', model: 'FileProcess' },
        filter: {
          _and: [
            { id_process_record: { _eq: mileagePrice.id } },
            { parameter: { process: { id: { _eq: this.idProcessMileage } } } },
            { parameter: { file_type: { name: { _eq: 'photo' } } } },
          ],
        },
        force: true,
      })
    }
    const mileageChileautos = val.mileagePrices?.find((item: any) => item.mileageFrom === 0 && item.mileageTo === 100 && item.person && item.dateTo === null && generation.id === item.idGeneration)

    if (mileageChileautos) {
      this.storeLinkChileautos = mileageChileautos.link
      this.formData.linkChileautos = mileageChileautos.link?.length ? mileageChileautos.link : this.formData.linkChileautos || ''
    } else {
      const attributes = [
        generation?.fuel,
        generation?.transmission,
        generation?.traction,
      ].filter(_ => _)

      this.formData.linkChileautos = val.buildLinkChileAutos(attributes, this.aliasBrand, this.aliasModel).replace(' ', '-')
    }
  }

  get isPhoto () {
    const { photo } = this

    return photo?.length > 0
  }

  get isPdf () {
    const { pdf } = this

    return pdf?.length > 0
  }

  get isSameLink () {
    const { formData, storeLinkChileautos } = this
    return storeLinkChileautos && formData.linkChileautos === storeLinkChileautos
  }

  get iconLinkChileautos () {
    const { isSameLink, send } = this
    return isSameLink || send ? 'mdi-content-save' : 'mdi-content-save-off'
  }

  get colorLinkChileautos () {
    const { isSameLink, send } = this

    return isSameLink || send ? 'green' : 'error'
  }

  get statusLinkChileautos () {
    const { isSameLink, send } = this

    return isSameLink || send ? 'Guardado' : 'Sin guardar'
  }

  get disabledSend () {
    const { isLinkValid, formData: { link, linkChileautos } } = this

    if (link?.length && !isLinkValid(link)) {
      return true
    }

    return Boolean(linkChileautos?.length && !isLinkValid(linkChileautos))
  }

  get is0kmRequired () {
    const { linkModel, data: { year: { id } } } = this

    const year = dayjs().year() - 2

    if (id < year) {
      return false
    }

    return !linkModel?.length
  }

  get someRequired () {
    const { formData: { amount, amountFinanced, link, backupPhoto }, is0kmRequired } = this

    return Boolean(backupPhoto?.length) || Boolean(isValidNumber(amount) || isValidNumber(amountFinanced) || link?.length) || is0kmRequired
  }

  @Watch('linkModel', { immediate: true })
  async onLinkModelChange (val: any) {
    if (val?.length) {
      this.formData.link = val
    }
  }

  @Watch('backupPhoto', { immediate: true })
  async onBackupPhotoChange (val: any) {
    if (val?.length) {
      this.formData.backupPhoto = val
    }
  }

  @Watch('aliasModel', { immediate: true, deep: true })
  @Debounce(1000)
  onAliasChange (val) {
    if (!val?.length) {
      if (this.originalLinkChileautos) {
        this.formData.linkChileautos = this.originalLinkChileautos
      }
      return
    }

    // Si es la primera vez que cambia, guardamos el valor original
    if (!this.originalLinkChileautos) {
      this.originalLinkChileautos = this.formData.linkChileautos
    }
    const linkParts = this.formData.linkChileautos.split('/')

    const modelPosition = 3 // posición fija donde siempre se encuentra el modelo

    // Verificamos que el link tenga suficientes partes
    if (linkParts.length > modelPosition) {
      // Sustituimos el valor del modelo en la posición definida
      linkParts[modelPosition] = val
    }

    // Reconstruimos el link con el nuevo valor para el modelo
    this.$set(this.formData, 'linkChileautos', linkParts.join('/'))
  }

  get validYear () {
    const { data } = this

    return (dayjs().year() - 2) <= data.year.id
  }
  }
